const PREFIX = 'PLAYER'

enum PlayerConstants {
    PLAYER_INITIALIZE,
    PLAYER_INITIALIZE_SUCCESS,
    PLAYER_INITIALIZE_ERROR,
    STORE_PLAYER_INITIALIZE,
    STORE_PLAYER_INITIALIZE_ERROR,
    GET_SETTINGS,
    GET_SETTINGS_SUCCESS,
    DELETE_SETTINGS,
    DELETE_SETTINGS_SUCCESS,
    GET_PLAYERS,
    GET_PLAYERS_ERROR,
    GET_PLAYERS_SUCCESS,
    PLAYER_EVENT,
    REMOVE_PLAYER_FROM_EVENT,
    CLEAR_PLAYER_EVENT,
    NEW_BOOKED_EVENT,
    BOOK_EVENT,
    BOOK_EVENT_SUCCESS,
    BOOK_EVENT_ERROR,
    GET_EVENT,
    GET_EVENT_SUCCESS,
    GET_EVENT_ERROR,
    DELETE_ALERT,
    DELETE_ALERT_SUCCESS,
    HIDE_ALERT,
    HIDE_ALERT_SUCCESS,
    NEW_HIDDEN_ALERT,
    GET_PLAYER_ODDS_EVENTS_SUCCESS,
    GET_PLAYER_ODDS_EVENTS,
    GET_EVENTS,
    GET_EVENTS_SUCCESS,
    ADD_TO_BLACKLIST,
    SET_SETTINGS_AVERAGE_BOOKMAKERS,
    SEARCH_PLAYER_EVENT,
    SEARCH_PLAYER_EVENT_SUCCESS
}

const SETTINGS = 'PLAYER_SETTINGS'

enum SettingConstants {
    SET_ORDER,
    SET_SPORT_FILTER,
    SET_SETTINGS,
}

export enum OrderConstants {
    NONE,
    DATE,
    MARGIN,
    MARGIN_REV,
    COMPARISON,
    BOOK,
}

export const STORE_PLAYER_INITIALIZE = PREFIX + '_' + PlayerConstants.STORE_PLAYER_INITIALIZE;
export const STORE_PLAYER_INITIALIZE_ERROR = PREFIX + '_' + PlayerConstants.STORE_PLAYER_INITIALIZE_ERROR;
export const PLAYER_INITIALIZE = PREFIX + '_' + PlayerConstants.PLAYER_INITIALIZE;
export const PLAYER_INITIALIZE_SUCCESS = PREFIX + '_' + PlayerConstants.PLAYER_INITIALIZE_SUCCESS;
export const PLAYER_INITIALIZE_ERROR = PREFIX + '_' + PlayerConstants.PLAYER_INITIALIZE_ERROR;
export const GET_SETTINGS = PREFIX + '_' + PlayerConstants.GET_SETTINGS;
export const GET_SETTINGS_SUCCESS = PREFIX + '_' + PlayerConstants.GET_SETTINGS_SUCCESS;
export const DELETE_SETTINGS = PREFIX + '_' + PlayerConstants.DELETE_SETTINGS;
export const DELETE_SETTINGS_SUCCESS = PREFIX + '_' + PlayerConstants.DELETE_SETTINGS_SUCCESS;

export const SEARCH_PLAYER_EVENT = PREFIX + '_' + PlayerConstants.SEARCH_PLAYER_EVENT
export const SEARCH_PLAYER_EVENT_SUCCESS = PREFIX + '_' + PlayerConstants.SEARCH_PLAYER_EVENT_SUCCESS;
export const GET_PLAYERS = PREFIX + '_' + PlayerConstants.GET_PLAYERS;
export const GET_PLAYERS_SUCCESS = PREFIX + '_' + PlayerConstants.GET_PLAYERS_SUCCESS;
export const PLAYER_EVENT = PREFIX + '_' + PlayerConstants.PLAYER_EVENT;
export const REMOVE_PLAYER_FROM_EVENT = PREFIX + '_' + PlayerConstants.REMOVE_PLAYER_FROM_EVENT;
export const CLEAR_PLAYER_EVENT = PREFIX + '_' + PlayerConstants.CLEAR_PLAYER_EVENT;

export const GET_PLAYER_ODDS_EVENTS = PREFIX + '_' + PlayerConstants.GET_PLAYER_ODDS_EVENTS;
export const GET_PLAYER_ODDS_EVENTS_SUCCESS = PREFIX + '_' + PlayerConstants.GET_PLAYER_ODDS_EVENTS_SUCCESS;

export const GET_EVENT = PREFIX + '_' + PlayerConstants.GET_EVENT;
export const GET_EVENT_SUCCESS = PREFIX + '_' + PlayerConstants.GET_EVENT_SUCCESS;
export const GET_EVENT_ERROR = PREFIX + '_' + PlayerConstants.GET_EVENT_ERROR;
export const GET_EVENTS = PREFIX + '_' + PlayerConstants.GET_EVENTS;
export const GET_EVENTS_SUCCESS = PREFIX + '_' + PlayerConstants.GET_EVENTS_SUCCESS;

export const BOOK_EVENT = PREFIX + '_' + PlayerConstants.BOOK_EVENT;
export const BOOK_EVENT_SUCCESS = PREFIX + '_' + PlayerConstants.BOOK_EVENT_SUCCESS;
export const BOOK_EVENT_ERROR = PREFIX + '_' + PlayerConstants.BOOK_EVENT_ERROR;

export const NEW_BOOKED_EVENT = PREFIX + '_' + PlayerConstants.NEW_BOOKED_EVENT;
export const DELETE_ALERT = PREFIX + '_' + PlayerConstants.DELETE_ALERT;
export const DELETE_ALERT_SUCCESS = PREFIX + '_' + PlayerConstants.DELETE_ALERT_SUCCESS;
export const HIDE_ALERT = PREFIX + '_' + PlayerConstants.HIDE_ALERT;
export const HIDE_ALERT_SUCCESS = PREFIX + '_' + PlayerConstants.HIDE_ALERT_SUCCESS;
export const NEW_HIDDEN_ALERT = PREFIX + '_' + PlayerConstants.NEW_HIDDEN_ALERT;

export const ADD_TO_BLACKLIST = PREFIX + '_' + PlayerConstants.ADD_TO_BLACKLIST;
export const SET_SETTINGS_AVERAGE_BOOKMAKERS = PREFIX + '_' + PlayerConstants.SET_SETTINGS_AVERAGE_BOOKMAKERS;


export const SET_SPORT_FILTER = SETTINGS + '_' + SettingConstants.SET_SPORT_FILTER;
export const SET_ORDER = SETTINGS + '_' + SettingConstants.SET_ORDER;
export const SET_SETTINGS = SETTINGS + '_' + SettingConstants.SET_SETTINGS;

export const ENABLED_BOOKMAKERS = new Set<number>([0, 1, 2, 26, 12, 31, 33, 34, 102, 107, 114, 119, 120, 134, 140, 141, 142, 143, 144])

export const MIN_LIMIT = -10;
export const MAX_LIMIT = 5;